import { makeObservable, observable, action, flow, computed } from 'decorators';
import api from 'services/api';
import log from 'services/log';
import auth from 'services/auth';
import { installScript } from 'utils/dom';

export const StipeApiVersion = '2024-10-28.acacia';

let loadingPromise;
export function getStripe() {
  if (!loadingPromise) {
    loadingPromise = installScript('https://js.stripe.com/v3/')
      .catch((err) => {
        loadingPromise = null;
        throw err;
      });
  }
  return loadingPromise;
}

export default class StripePaymentStore {
  @observable checkout = null;
  @observable isCompleting = false;

  constructor(id, session, onReturn) {
    makeObservable(this);
    this._id = id;
    this._session = session;
    this._onReturn = onReturn;

    // pre-init so the payment is faster when they click the button...
    this._setup()
      .catch(err => log.error('Problem loading Stripe', err))
      .done();
  }

  @computed get orgId() {
    return this._session.orgId;
  }

  cancel() {
    this._onReturn();
  }

  dispose() {
    if (this.checkout) { this.checkout.destroy(); }
  }

  @flow *_setup() {
    yield getStripe();

    try {
      this._stripe = window.Stripe(this._session.appId, { stripeAccount: this._session.userId, apiVersion: StipeApiVersion });
      this.checkout = yield this._stripe.initEmbeddedCheckout({
        fetchClientSecret: async() => {
          const res = await api.postAnon(`paymentsession/${this._id}/stripe`);
          return res.data.clientSecret;
        },
        onComplete: action(() => {
          this.isCompleting = true;
          this._pollComplete();
        })
      });
    } catch (err) {
      auth.setNotValidLink('Checkout', 'Could not find Checkout session');
    }
  }

  async _pollComplete() {
    const res = await api.getAnon('paymentsession/' + this._id);
    if (res.data.isComplete) { this._onReturn(); return; }

    setTimeout(() => this._pollComplete(), 5000);
  }
}