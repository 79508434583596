import { useCallback, useState } from 'react';
import { observer } from 'decorators';
import { makeStyles } from 'hooks';

import auth from 'services/auth';
import config from 'config';
import theme from 'theme';
import { splitWindowQuery } from 'utils/query';

import { Paper } from '@material-ui/core';
import Modal from 'components/modal';
import NavLink from 'components/navLink';
import Image from 'components/image';

import { CheckCircleOutline } from 'mdi-material-ui';

export const useSuccessBoxStyles = makeStyles(theme => ({
  logo: {
    display: 'block',
    width: '50%',
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    maxWidth: 200
  },
  container: {
    padding: theme.spacing(4, 2),
    textAlign: 'center'
  },
  iconImage: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: theme.palette.grey[700],
    paddingBottom: theme.spacing(2)
  },
  error: {
    color: theme.palette.error.main
  }
}));

export const SuccessBox = observer(function SuccessBox({ children, afterImageSection, showLogo, orgLogo, orgId, icon, hideFallback, containerClassName, width, isHidden }) {
  const [ loaded, setLoaded ] = useState(false);
  const classes = useSuccessBoxStyles();

  const url = (orgLogo ? auth.user?.website : null) || (hideFallback ? null : theme.brand.frontPageUrl);
  const orgName = (orgLogo ? auth.user?.orgPublicName : null) || (hideFallback ? null : theme.brand.name);
  orgId = orgId || (orgLogo ? (auth.user?.orgId || splitWindowQuery().query.orgHint) : null);

  const handleLoad = useCallback(() => setLoaded(true), []);
  let image = showLogo && <Image
    src={orgId ? `${config.anon_path}/org/${orgId}/logo` : undefined}
    fallbackSrc={hideFallback ? undefined : theme.brand.logoUrl}
    className={classes.logo}
    alt={orgName}
    onLoad={handleLoad}
  />;

  if (image && url) {
    image = <NavLink href={url}>
      { image }
    </NavLink>;
  }

  return <Modal open hideBackdrop width={width || 400} PaperComponent="div" style={ isHidden ? { visibility: 'hidden' } : undefined}>
    { image &&
      <div style={{ display: loaded ? undefined : 'none' }}>
        { image }
      </div>
    }
    { afterImageSection }
    <Paper className={containerClassName || classes.container} elevation={2}>
      { icon || <CheckCircleOutline className={classes.iconImage} /> }
      { children }
    </Paper>
  </Modal>;
});